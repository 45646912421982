import { Component } from "react";

export default class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    render() {
        return (
            <main className='px-8 py-8 flex flex-col'>
                <h1 className='text-2xl font-bold mb-4'>404 Not Found</h1>
            </main>
        );
    };
}