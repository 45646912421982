import { Component } from "react";

export default class Terms extends Component {
    render() {
        return (
            <main className='mx-8 lg:mx-64 my-8 flex flex-col'>
                <section className="mx-4 lg:mx-32 xl:mx-48 2xl:mx-96 text-black">
                    <h1 className="text-4xl font-medium mb-4">
                        Sıkça Sorulan Sorular
                    </h1>

                    <h3 className="text-xl font-medium mb-4 mt-4">Premium Üyelik Ödemeleri</h3>
                    <p className="mt-2">Yapılan ödemeler seçilen süre için tek seferde tahsil edilir, iptal edilmediği sürece süre bitiminde otomatik olarak tekrar uzar ve belirtilen tutar tekrar tahsil edilir.</p>

                    <section>
                        <h3 className="text-xl font-medium mb-4 mt-4">Doping Ödemeleri</h3>
                        <p className="mt-2">Yapılan ödemeler tek seferlik tahsil edilir, tekrarlı değildir. Dopinglerin kullanımı için süre sınırı yoktur.</p>
                    </section>

                    <section>
                        <h3 className="text-xl font-medium mb-4 mt-4">Giriş Sayfası</h3>
                        <p className="mt-2">Giriş sayfasından, isterseniz mevcut google ve apple hesaplarınızı kullanarak , isterseniz de hızlı bir şekilde yeni kullanıcı oluşturarak giriş yapabilirsiniz.</p>
                    </section>

                    <section>
                        <h3 className="text-xl font-medium mb-4 mt-4">Bilet Bul</h3>
                        <p className="mt-2">Bilet Bul sayfasında, tüm müsabakalara ait ilanları görüntüleyebilir ve yukarıdaki butonlar aracılığı ile filtreleyebilirsiniz.</p>
                        <p className="mt-2">İlan kutularının görünümleri seçilen dopinge göre farklılık gösterebilir.</p>
                    </section>

                    <section>
                        <h3 className="text-xl font-medium mb-4 mt-4">İlan Ver</h3>
                        <p className="mt-2">İlan Ver sayfasından, elinizde bulunan kombine/ler için ilan oluşturabilirsiniz. (Sadece Premium üyeler aynı anda 1' den fazla ilan oluşturabilir.)</p>
                        <p className="mt-2">İsterseniz detay bilgilerini tamamladıktan sonra ilanınız için doping satın alabilirsiniz. 3 farklı doping tipi bulunmaktadır. “Kalın Yazı”, “Renkli Çerçeve” ve “Acil İkonu” . Bu dopingleri 1-3-5 adet şeklinde topluca da satın alabilirsiniz. İstediğiniz ilana seçimli olarak kullanabilirsiniz.</p>
                    </section>

                    <section>
                        <h3 className="text-xl font-medium mb-4 mt-4">İlan Detay</h3>
                        <p className="mt-2">İstediğiniz ilana tıklayarak maça ve bilete ait detay bilgilere ulaşabilirsiniz.</p>
                    </section>

                    <section>
                        <h3 className="text-xl font-medium mb-4 mt-4">Profil</h3>
                        <h2 className="font-bold">İlanlarım</h2>
                        <p>Bu sayfada; yeni ilan verme, ilanı düzenleme, yayından kaldırma ve doping satın alma işlevlerini gerçekleştirebilirsiniz.</p>
                        <br></br>

                        <h2 className="font-bold">Favori İlanlar</h2>
                        <p>Bu sayfada; favorilere eklediğiniz ilanlarınızı görüntüleyebilirsiniz (*Premium Üyelik).</p>
                        <br></br>

                        <h2 className="font-bold">Favori Kullanıcılar</h2>
                        <p>Bu sayfada; favorilere eklediğiniz kullanıcıları görüntüleyebilirsiniz (*Premium Üyelik).</p>
                        <br></br>

                        <h2 className="font-bold">Kombinem</h2>
                        <p>Bu sayfada; elinizde bulunan kombine biletlerini kaydedebilir ve ilan verirken otomatik olarak kullanabilirsiniz. 1' den fazla kombine kaydı oluşturmak (*Premium Üyelik) gerektirmektedir.</p>
                        <br></br>

                        <h2 className="font-bold">Telefon Numaramı Gizle</h2>
                        <p>Telefon numaranızı gizlemek istediğinizde bu özelliği aktif hale getirebilirsiniz. (*Premium Üyelik)</p>
                        <br></br>

                        <h2 className="font-bold">Adımı Soyadımı Gizle</h2>
                        <p>Ad-Soyadınızı gizlemek istediğinizde bu özelliği aktif hale getirebilirsiniz. (*Premium Üyelik)</p>

                    </section>

                </section>
            </main>
        );
    }
}