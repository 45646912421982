import { Component } from "react";

export default class Terms extends Component {
    render() {
        return (
            <main className='mx-8 lg:mx-64 my-8 flex flex-col'>
                <section className="mx-4 lg:mx-32 xl:mx-48 2xl:mx-96 text-black">
                    <h1 className="text-4xl font-medium mb-4">
                        Kullanım Şartları
                    </h1>

                    <h3 className="text-xl font-medium mb-4 mt-4">1. Taraflar</h3>
                    <p>
                        İşbu sözleşme ve sözleşmeyle atıfta bulunulan ve sözleşmenin
                        ayrılmaz birer parçası olan Gizlilik Politikası ile oluşan işbu
                        Kombine Transferi Üyelik Sözleşmesi (bundan böyle “üyelik Sözleşmesi”
                        olarak anılacaktır), Kombine Transferi ve Yazılım A.Ş. (bundan böyle
                        Kombine Transferi olarak anılacaktır) ile kombinetransferi.com sitesine
                        işbu Üyelik Sözleşmesi’nde belirtilen koşullar dahilinde kayıt
                        olan Üye arasında, Üye’nin elektronik olarak onay vermesi ile
                        karşılıklı olarak kabul edilerek ve 4. maddedeki şarta bağlı
                        olarak yürürlüğe girmiştir.
                        <br />
                        Üye, işbu Üyelik Sözleşmesini kabul etmeden önce,
                        https://kombinetransferi.com/kullanici-sozlesmesi bağlantısından
                        ulaşılan ve indirilebilen, ticari amaçlarla verilen sözleşme
                        öncesi bilgilendirme metnini okuyup
                        incelediğini,şartlarınıanladığını, bilgilendirme metnini
                        bilgisayarına indirebildiğini ve sözleşme öncesi bilgilendirme
                        yükümlülüğünün kendisine karşı tam olarak yerine getirildiğini,
                        sözleşme ile ilgili ön bilgileri edindiğini teyit ettiğini beyan
                        ve kabul eder.
                    </p>
                    <p>
                        Üye, Üyelik Sözleşmesi sürecine ilişkin işlemleri tamamlayarak
                        işbu Üyelik Sözleşmesinin tamamını okuduğunu, içeriğini tamamen
                        anladığını, kendisi ile ilgili olarak verdiği bilgilerin doğru ve
                        güncel olduğunu ve Üyelik Sözleşmesinde belirtilen ve Web
                        Sitesi’nde de yer alan işbu Üyelik Sözleşmesi konusu ile ilgili
                        tüm hususları kayıtsız ve şartsız olarak kabul ettiğini ve
                        onayladığını, bu hususlar ile ilgili olarak herhangi bir itiraz ve
                        defi ileri sürmeyeceğini şimdiden beyan, kabul ve taahhüt eder.
                        Üye, Üyelik Sözleşmesi konusunu oluşturan hizmetin içeriği, esaslı
                        nitelikleri, özellikleri, ücret talep edilmediği ile ilgili
                        bilgileri okuyup hizmetler hakkında gerekli tüm bilgilere sahip
                        olduğunu, bu bilgiler doğrultusunda elektronik ortamda Üyelik
                        Sözleşmesinenilişkin gerekli onayı verdiğini beyan, kabul ve
                        taahhüt eder.
                    </p>
                    <p>
                        Bu sözleşmeyi bilgisayarınıza indirebilir ve elektronik ortamda
                        kaydedebilirsiniz. Üye, işbu Üyelik Sözleşmesi metnini
                        bilgisayarına indirdiğini ve Üyelik Sözleşmesinin bu şekildekalıcı
                        veri saklayıcısı ile kendisine verildiğini beyan ve kabul eder.
                        Üye tarafından onaylanarak kabul edilen işbu Üyelik Sözleşmesi
                        Kombine Transferi tarafından saklanmamaktadır. Web Sitesi’nde yer alan
                        standart sözleşmeye her zaman ulaşmanız mümkündür. Ancak, standart
                        sözleşmede değişiklikler yapılmış olabileceğinden, Üye’nin
                        onayladığı metne göre farklılıklar içerebilir. Üye’nin onayladığı
                        metne daha sonra ulaşma imkanı bulunmamaktadır.
                    </p>
                    <p>
                        Kombine Transferi’nin 6698 sayılı Kişisel Verilerin Korunması Kanunu
                        kapsamında hazırladığı, kişisel verilerin işlenmesi ile ilgili
                        aydınlatma metni Web Sitesi’nde yayınlanmaktadır. Üye,
                        anılanaydınlatma metnini ve gizlilik politikasını okuyup
                        incelediğini, Kombine Transferi’nin ilgili kanun kapsamındaki
                        aydınlatmayükümlülüğünü kendisine karşı yerine getirdiğini beyan
                        ve kabul eder.
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">2. Tanımlar</h2>
                    <p>
                        Kombine Transferi: Kombine Transferi ve Yazılım A.Ş.
                        <br />
                        Üye: Site’ye üye olan ve Site’de sunulan Hizmet’lerden, işbu
                        sözleşmede belirtilen koşullar dahilinde yararlanan, sitedeki
                        ürünlere yorum yapan gerçek veya tüzel kişi.
                    </p>
                    <p>
                        Kullanıcı(lar): Site'ye çevrimiçi (on-line) ortamdan erişen gerçek
                        ve tüzel kişi(ler);
                    </p>
                    <p>
                        Site veya Web Sitesi: kombinetransferi.com isimli alan adından ve bu
                        alan adına bağlı alt alan adlarından oluşan web sitesi
                    </p>
                    <p>
                        Satıcı(lar): Satıcıların kendi internet sitelerinde satışa sunduğu
                        mal ve ürünler hakkındaki açıklayıcı bilgilerin ve satış
                        koşullarının Site üzerinden Kullanıcılar’a ve Üyeler’e liste
                        halinde sunulmasını sağlamak amacıyla Kombine Transferi ile Satıcı
                        sözleşmesi imzalayan gerçek ve/veya tüzel kişi(ler);
                    </p>
                    <p>
                        Kombine Transferi Hizmetleri (Kısaca "Hizmet"): Üyeler’ in Üyelik
                        Sözleşmesi’nde tanımlı olan iş ve işlemlerini gerçekleştirmelerini
                        sağlamak amacıyla, Kombine Transferi tarafından, Site içerisinde ortaya
                        konulan uygulamalar
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">
                        3. Sözleşmenin Konusu ve Kapsamı
                    </h2>
                    <p>
                        Üyelik Sözleşmesi’nin konusu, Site’de sunulan Hizmet’lerin, bu
                        Hizmet’lerden yararlanma şartlarının ve tarafların hak ve
                        yükümlülüklerinin tespitidir. Üye, Üyelik Sözleşmesi’nin
                        hükümlerini kabul etmekle, Site içinde yer alan, kullanıma
                        kurallarını, üyeliğe ve Hizmet’lere ilişkin olarak Kombine Transferi
                        tarafından açıklanan her türlü usul, kural ve beyanı da kabul
                        etmiş olmaktadır. Üye, bahsi geçen usul, kural ve beyanlarda
                        belirtilen her türlü hususa uygun olarak davranacağını kabul,
                        beyan ve taahhüt eder.
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">
                        4. Üyelik ve Hizmet Kullanımı Şartları
                    </h2>
                    <p>
                        Web Sitesi, internet üzerinden çeşitli ürünlerin fiyat ve diğer
                        özelliklerinin karşılaştırılması hizmeti verilen elektronik bir
                        platformdur. Çeşitli mal ve hizmetlerin satışı işiyle uğraşan
                        firmalar (Satıcılar), daha geniş bir tüketici kitlesine ulaşmak
                        amacıyla, kendi kendi ürünlerinin Web Sitesinde gösterilmesi için
                        Site’ye üye olarak bu hizmetten yararlanabilmektedirler.
                        Şirketimiz hiçbir ürünün satıcısı veya üreticisi değildir ve ürün
                        satışı veya üretimi ile iştigal etmemektedir. İnternet sitemizde
                        de hiçbir ürün satışı yapılmamaktadır. Şirketimiz, tüketicilere
                        ürün veya hizmet satışı zincirinin hiçbir aşamasında yer
                        almamaktadır. Kombine Transferi, Üye ile Satıcılar arasındaki ilişkiler
                        nedeniyle hiçbir şekilde sorumlu tutulamaz. Kombine Transferi, hiçbir
                        şekilde, Satıcıların Üye ile sözleşme yapacaklarını, iyi niyetli
                        olduklarını, borçlarını ifa kabiliyetlerinin varlığını,
                        borçlarının ifasını, beyanlarının doğruluğunu, işlem yapmaya
                        yetkili olduklarını,sağlanan mal ve hizmetlerin ayıpsız olacağını
                        ve benzer diğer hususları garanti etmez. Kombine Transferi, Üye’nin Site
                        vasıtasıyla ulaştığı Satıcılarla gireceği hukuki ilişkilere
                        tamamen yabancıdır ve bunlara ilişkin hiçbir sorumluluğu yoktur.
                        Sorumluluğun tamamı işlemin tarafı olan Satıcılara ve Üye’ye
                        aittir. 5651 sayılı yasa kapsamında “Yer Sağlayıcı” statüsünde
                        olan Kombine Transferi, Satıcılar tarafından sağlanan içeriği kontrol
                        etmek veya hukuka aykırı bir faaliyetin olup olmadığını
                        araştırmakla yükümlü değildir.
                    </p>
                    <p>
                        Üyelik, Site’nin ilgili bölümünden, veya sitedeki ürünlere yorum
                        yapmak suretiyle, Üye olmak isteyen kişi tarafından Site’ye üye
                        olmak için gerekli kimlik bilgilerinin gönderilmesi suretiyle
                        kayıt işleminin yaptırılması ve Kombine Transferi tarafından kayıt
                        işleminin onaylanması ile tamamlanır. Üyelik işlemi tamamlanmadan,
                        işbu sözleşmede tanımlanan Üye olma hak ve yetkisine sahip
                        olunamaz.
                    </p>
                    <p>
                        Üye’nin Hizmetlerden yararlanması ücretsizdir. Kombine Transferi, ilerde
                        Hizmetleri ücretli hale getirme ve/veya ücret karşılığı Hizmetler
                        ilave etme hakkınısaklı tutar.
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">
                        5. Tarafların Hak ve Yükümlülükleri
                    </h2>
                    <p>
                        5.1 Üye, üyelik prosedürlerini yerine getirirken, Site’nin
                        Hizmet’lerinden faydalanırken ve Site’deki Hizmet’lerle ilgili
                        herhangi bir işlemi yerine getirirken, Üyelik Sözleşmesi’nde yer
                        alan tüm şartlara, Site’nin ilgili yerlerinde belirtilen kurallara
                        ve yürürlükteki tüm mevzuata uygun hareket edeceğini, yukarıda
                        belirtilen tüm şart ve kuralları anladığını ve onayladığını kabul,
                        beyan ve taahhüt eder.
                        <br />
                        5.2 Üye, yürürlükteki emredici mevzuat hükümleri gereğince veya
                        diğer üyeler ve Kullanıcılar ile üçüncü şahısların haklarının
                        ihlal edildiğinin iddia edilmesi durumlarında, Kombine Transferi’nin
                        kendisine ait gizli/özel/ticari bilgileri gerek resmi makamlara ve
                        gerekse hak sahibi kişilere açıklamaya yetkili olacağını ve bu
                        sebeple Kombine Transferi’den her ne nam altında olursa olsun tazminat
                        talep edilemeyeceğini kabul, beyan ve taahhüt eder.
                    </p>
                    <p>
                        5.3 Üyeler’in Kombine Transferi tarafından sunulan Hizmet’lerden
                        yararlanabilmek amacıyla kullandıkları sisteme erişim araçlarının
                        (Kullanıcı ismi, şifre v.b.) güvenliği, saklanması, üçüncü
                        kişilerin bilgisinden uzak tutulması ve kullanılması durumlarıyla
                        ilgili hususlar tamamen Üyeler’in sorumluluğundadır. Üyeler’in,
                        sisteme giriş araçlarının güvenliği, saklanması, üçüncü kişilerin
                        bilgisinden uzak tutulması, kullanılması gibi hususlardaki tüm
                        ihmal ve kusurlarından dolayı Üyeler’in ve/veya üçüncü kişilerin
                        uğradığı veya uğrayabileceği zararlara istinaden Kombine Transferi’nin,
                        doğrudan veya dolaylı, herhangi bir sorumluluğu yoktur.
                    </p>
                    <p>
                        5.4 Üyeler, Kombine Transferi’un yazılı onayı olmadan, Üyelik Sözleşmesi
                        kapsamındaki hak ve yükümlülüklerini, kısmen veya tamamen,
                        herhangi bir üçüncü kişiye devredemezler.
                    </p>
                    <p>
                        5.5 Kombine Transferi, Üyeler’in Site’de listelenmesine yer verilen ürün
                        açıklamalarına ulaşmasını ve ürünlerin model, cins, vasıf, nitelik
                        ve/veya özellikleri ile ilgili her türlü karşılaştırılmanın
                        yapılmasını sağlayacaktır. Kombine Transferi, Üyeler’in Üyelik
                        Sözleşmesi’nde tanımlı olan iş ve işlemleri daha etkin şekilde
                        gerçekleştirebilmelerini sağlamak üzere, dilediği zaman
                        Hizmet’lerinde değişiklikler ve/veya uyarlamalar yapabileceği gibi
                        ek Hizmetler de sunabilir. Kombine Transferi tarafından yapılan bu
                        değişiklikler ve/veya uyarlamalar ve/veya eklenen yeni Hizmetler
                        Kombine Transferi tarafından, ilgili Hizmet’in kullanımıyla ilgili
                        açıklamaların bulunduğu web web sayfasından Üyeler’e duyurulur.
                    </p>
                    <p>
                        5.6 Kombine Transferi’nin sunduğu Hizmet’lerden yararlananlar ve Site’yi
                        kullananlar, yalnızca hukuka uygun amaçlarla Site üzerinde işlem
                        yapabilirler. Üyeler’in, Site dahilinde yaptığı her işlem ve
                        eylemdeki hukuki ve cezai sorumluluk kendilerine aittir. Her Üye,
                        Kombine Transferi ve/veya başka bir üçüncü şahsın ayni veya şahsi
                        haklarına veya malvarlığına tecavüz teşkil edecek şekilde, Site
                        dahilinde bulunan resimleri, metinleri, görsel ve işitsel
                        imgeleri, video kliplerini, dosyaları, veritabanlarını,
                        katalogları ve listeleri çoğaltmayacağını, kopyalamayacağını,
                        dağıtmayacağını, işlemeyeceğini, gerek bu eylemleri ile gerekse de
                        başka yollarla Kombine Transferi ile doğrudan ve/veya dolaylı olarak
                        rekabete girmeyeceğini kabul, beyan ve taahhüt eder. Kombine Transferi,
                        Üyeler’in Üyelik Sözleşmesi hükümlerine ve/veya hukuka aykırı
                        olarak Site üzerinde gerçekleştirdikleri faaliyetler nedeniyle
                        üçüncü kişilerin uğradıkları veya uğrayabilecekleri zararlardan
                        doğrudan ve/veya dolaylı olarak, hiçbir şekilde sorumlu tutulamaz.
                    </p>
                    <p>
                        5.7 Kombine Transferi, Site’de sunulan Hizmetler’i ve içerikleri her
                        zaman değiştirebilme; işbu içerikleri Üyeler ve Siteyi kullananlar
                        da dahil olmak üzere, üçüncü kişilerin erişimine kapatabilme ve
                        silme hakkını saklı tutmaktadır. Kombine Transferi, bu hakkını hiçbir
                        bildirimde bulunmadan ve önel vermeden kullanabilir.
                    </p>
                    <p>
                        5.8 Kombine Transferi, Site’de yer alan Üye bilgilerini, üye güvenliği,
                        kendi yükümlülüğünü ifa ve bazı istatistiki değerlendirmeler için
                        dilediği biçimde kullanabilir. Bunları bir veritabanı üzerinde
                        tasnif edip muhafaza edebilir.
                    </p>
                    <p>
                        5.9 Üyeler; Site üzerinden erişim verilen ürünlerin satışının
                        gerçekleşeceği Satıcılar’a ait internet sitelerinde, Satıcılar
                        tarafından satışa arz edilen ürün/ürünler ve/veya yayınlanan
                        içerik/içeriklerden dolayı Kombine Transferi’nin, Kombine Transferi
                        çalışanlarının ve yöneticilerinin hiçbir şekilde sorumluluğu
                        bulunmadığını kabul, beyan ve taahhüt eder.
                    </p>
                    <p>
                        5.10 Üyeler; Siteye üye olarak sitenin bilgilendirme amaçlı e-mail
                        göndermesini kabul eder. Sitedeki ürünlere yorum yazan
                        kullanıcıların e-mail adreslerinin ve/veya içeriklerinin kullanım
                        hakkı kombinetransferi.com’da saklı olup Üye, kombinetransferi.com’dan e-mail
                        almayı kabul etmiş sayılmaktadır.
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">
                        6. Gizlilik Politikası
                    </h2>
                    <p>
                        Kombine Transferi, Üyeler’e ilişkin bilgileri, işbu sözleşmenin EK-1
                        Gizlilik Politikası bölümündeki düzenlemelere uygun olarak
                        kullanabilir. Kombine Transferi, Üyeler’e ait gizli bilgileri, Üyelik
                        Sözleşmesi’nde aksine müsaade edilen durumlar haricinde, üçüncü
                        kişi ve kurumlara kullandırmaz.
                    </p>
                    <h2 className="text-xl font-medium mb-4 mt-4">7. Diğer Hükümler</h2>
                    <p>7.1. Fikri Mülkiyet Hakları</p>
                    <ol>
                        <li>
                            Site’nin tasarım, metin, imge, html kodu ve diğer kodlar da
                            dahil ve fakat bunlarla sınırlı olmamak kaydıyla tüm unsurları
                            (bundan böyle “Kombine Transferi’nin Telif Haklarına Tabi Çalışmalar”
                            olarak anılacaktır) Kombine Transferi’ye aittir ve/veya Kombine Transferi
                            tarafından üçüncü bir kişiden alınan lisans hakkı altında
                            kullanılmaktadır. Üyeler, Hizmetler’i, Kombine Transferi bilgilerini ve
                            Kombine Transferi’nin Telif Haklarına Tabi Çalışmaları’nı yeniden
                            satamaz, paylaşamaz, dağıtamaz, sergileyemez veya başkasının
                            Hizmetler’e erişmesine veya kullanmasına izin veremez. Aksi
                            takdirde, lisans verenler de dahil üçüncü kişilerin ve
                            Kombine Transferi’nin uğradıkları zararları ve/veya bu zararlardan
                            dolayı Kombine Transferi’den talep edilen tazminat miktarını, mahkeme
                            masrafları ve avukatlık ücreti de dahil olmak üzere,
                            karşılamakla yükümlü olacaklardır. Üyeler, Kombine Transferi’nin Telif
                            Haklarına Tabi Çalışmaları’nı çoğaltamaz, dağıtamaz veya
                            bunlardan türemiş çalışmalar yapamaz veya hazırlayamaz.
                        </li>
                        <li>
                            Kombine Transferi’nun, Hizmetler’i, bilgileri, Kombine Transferi Telif
                            Haklarına Tabi Çalışmaları, ticari markaları, ticari görünümü
                            veya Site vasıtasıyla sahip olduğu her tür maddi ve fikri
                            mülkiyet hakları da dahil tüm malvarlığı, ayni ve şahsi hakları,
                            ticari bilgi ve know-how'a yönelik tüm hakları saklıdır.
                        </li>
                    </ol>
                    <p>7.2. Sözleşme Değişiklikleri</p>
                    <p>
                        Kombine Transferi, tamamen kendi takdirine bağlı ve tek taraflı olarak,
                        işbu Üyelik Sözleşmesi’ni, uygun göreceği herhangi bir zamanda,
                        Site’de ilan etmek suretiyle değiştirebilir. İşbu Üyelik
                        Sözleşmesi’nin değişen hükümleri, ilan edildikleri tarihte
                        geçerlilik kazanacak; geri kalan hükümler, aynen yürürlükte
                        kalarak hüküm ve sonuçlarını doğurmaya devam edecektir. İşbu
                        Üyelik Sözleşmesi, Üye’nin tek taraflı beyanları ile
                        değiştirilemez.
                    </p>
                    <p>7.3. Mücbir Sebepler</p>
                    <p>
                        Hukuken mücbir sebep sayılan tüm durumlarda, Kombine Transferi, işbu
                        sözleşme ile belirlenen edimlerinden herhangi birini geç veya
                        eksik ifa etme veya ifa etmeme nedeniyle yükümlü değildir. Bu ve
                        bunun gibi durumlar, Kombine Transferi için, gecikme, eksik ifa etme veya
                        ifa etmeme veya temerrüt addedilmeyecek veya bu durumlar için
                        Kombine Transferi 'den herhangi bir nam altında tazminat talep
                        edilemeyecektir. "Mücbir sebep" terimi, doğal afet, isyan, savaş,
                        grev, iletişim sorunları, altyapı ve internet arızaları, elektrik
                        kesintisi ve kötü hava koşulları da dahil ve fakat bunlarla
                        sınırlı olmamak kaydıyla, ilgili tarafın makul kontrolü haricinde
                        ve Kombine Transferi'nin gerekli özeni göstermesine rağmen önleyemediği,
                        kaçınılamayacak olaylar olarak yorumlanacaktır.
                    </p>
                    <p>7.4. Uygulanacak Hukuk ve Yetki</p>
                    <p>
                        İşbu Kullanıcı Sözleşmesi’nin uygulanmasında, yorumlanmasında ve
                        hükümleri dahilinde doğan hukuki ilişkilerin yönetiminde Türk
                        Hukuku uygulanacaktır. İşbu Kullanıcı Sözleşmesi’nden doğan veya
                        doğabilecek her türlü ihtilafın hallinde, Antalya Mahkemeleri ve
                        İcra Daireleri yetkilidir.
                    </p>
                    <p>7.5. Sözleşmenin Feshi</p>
                    <p>
                        İşbu Üyelik Sözleşmesi, süresiz olarak düzenlenmiştir.
                        kombinetransferi.com herhangi bir süre tayinine gerek olmaksızın
                        istediği zaman sözleşmeyi fesih hakkına sahiptir.
                    </p>
                    <p>
                        Kombine Transferi, Üyeler’in işbu Üyelik Sözleşmesi’ni ve/veya, Site
                        içinde yer alan kullanıma, üyeliğe ve Hizmet’lere ilişkin benzeri
                        kuralları ihlal etmeleri durumunda ve özellikle aşağıda sayılan
                        hallerde, sözleşmeyi tek taraflı olarak feshedebilecek ve Üyeler,
                        fesih sebebiyle, Kombine Transferi’nin uğradığı tüm zararları tazmin
                        etmekle yükümlü olacaktır:
                    </p>
                    <ol>
                        <li>
                            Üye’nin, herhangi bir yöntem kullanarak, Site’nin işleyişini
                            manipüle edecek davranışlarda bulunması,
                        </li>
                        <li>
                            Site dahilinde bulunan resimleri, metinleri, görsel ve işitsel
                            imgeleri, dosyaları, veritabanlarını, katalogları ve listeleri
                            izinsiz olarak çoğaltması, kopyalaması, dağıtması ve işlemesi;
                        </li>
                        <li>
                            Üye’nin diğer Üye ve/veya kullanıcıların ve/veya üçüncü
                            kişilerin ve/veya hak sahiplerinin haklarına tecavüz eden
                            ve/veya etme tehlikesi bulunan fillerde bulunması.
                        </li>
                    </ol>
                    <p>7.6. Sözleşmenin Süresi</p>
                    <p>
                        İşbu Sözleşme süresiz olarak düzenlenmiştir. Taraflardan her biri
                        istediği zaman sözleşmeyi fesih hakkına sahiptir.
                    </p>
                </section>
            </main>
        );
    }
}