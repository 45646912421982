import { Component } from "react";

export default class Terms extends Component {
    render() {
        return (
            <main className='mx-8 lg:mx-64 my-8 flex flex-col'>
                <section className="mx-4 lg:mx-32 xl:mx-48 2xl:mx-96 text-black">
                    <h1 className="text-4xl font-medium mb-4">
                        Hakkımızda
                    </h1>
                    <h1>Kombine Transferi ile Farklı Bir Deneyim!</h1>
                    <p>Kombine Transferi, maç bileti alım-satım platformları arasında öne çıkan ücretsiz ve kullanışlı bir uygulamadır.</p>

                    <h3 className="text-xl font-medium mb-4 mt-4">Diğerlerinden Farkımız</h3>
                    <p>Diğer uygulamalardan farklı olarak kombine sahiplerine **ÜCRETSİZ** ilan imkanı sunarak, gidemedikleri maçların biletlerini kolayca satmalarına olanak tanır. Bilet bulmak isteyenler de **ÜCRETSİZ** olarak bu ilanları görüntüleyebilir ve satıcıyla iletişime geçebilir.</p>

                    <h3 className="text-xl font-medium mb-4 mt-4">Kombine Transferi ile Neler Yapabilirsiniz?</h3>
                    <ul>
                        <li>**Ücretsiz İlan Verme:** Kombine biletlerinizi **tamamen ücretsiz** olarak ilana koyabilir, potansiyel alıcılara ulaşabilirsiniz.</li>
                        <li>**Filtreleme Seçenekleri:** İlgi alanlarınıza uygun ilanları bulmak için takım, maç ve tarih kriterlerine göre filtreleme yapabilirsiniz.</li>
                        <li>**Stadyum Görselleri:** Satışa sunulan biletlerin hangi stadyumda ve hangi koltukta olduğunu görebilirsiniz.</li>
                        <li>**Favoriler:** Kullanıcıları ve beğendiğiniz ilanları favorilerinize ekleyerek kolayca takip edebilirsiniz.</li>
                        <li>**Hızlı İletişim:** İlan sahipleriyle anlık olarak mesajlaşabilir veya onları arayarak iletişim kurabilirsiniz.</li>
                        <li>**Geniş Spor Yelpazesi:** Futbol, basketbol, voleybol gibi tüm spor dallarına ait maçların biletlerini bulabilir veya satışa sunabilirsiniz.</li>
                    </ul>

                    <p>**Kombine Transferi'ni Hemen Deneyin!**</p>
                    <p>Kombine Transferi uygulamasını indirerek siz de bu kolaylıktan ve ücretsiz imkanlardan faydalanmaya başlayın. Maç bileti bulmak veya satmak artık çok daha kolay!</p>

                    <h3 className="text-xl font-medium mb-4 mt-4">Uygulamayı İndirin</h3>
                    <ul>
                        <li><a href="https://www.apple.com/app-store/">App Store</a></li>
                        <li><a href="https://support.google.com/googleplay/answer/190860?hl=en">Google Play Store</a></li>
                    </ul>

                </section>
            </main>
        );
    }
}