/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from 'react';
import './App.css';
import Privacy from './Privacy';
import Landing from './Landing';
import Terms from './Terms';
import FAQ from './FAQ';
import About from './About';
import NotFound from './NotFound';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

export default class App extends Component {
  render() {
    return (
      <main>
        <Router>
          <Switch>
            <Route path='/gizlilik-sozlesmesi' exact component={Privacy} />
            <Route path='/kullanim-sartlari' exact component={Terms} />
            <Route path='/sikca-sorulan-sorular' exact component={FAQ} />
            <Route path='/hakkimizda' exact component={About} />

            <Route path='/' exact component={Landing} />
            <Route path='*' exact component={NotFound} />
          </Switch>
        </Router>
      </main>
    );
  }
}
