import { Component } from "react";

export default class Privacy extends Component {
    render() {
        return (
            <main className='mx-8 lg:mx-64 my-8 flex flex-col'>
                <section className="lg:my-8 lg:mx-32 xl:mx-48 2xl:mx-96 mx-4">
                    <h1 className="text-black text-4xl font-medium mb-4">
                        Gizlilik Politikası
                    </h1>

                    <p className="text-black">
                        kombinetransferi.com alan adı web sitesinin (kısaca “Site” olarak
                        anılacaktır) tüm hakları Kombine Transferi ve Yazılım A.Ş.’ne
                        (kısaca ”Kombine Transferi” olarak anılacaktır) aittir. Kombine Transferi, Site
                        üzerinden kullanıcılar tarafından kendisine elektronik ortamda
                        iletilen kişisel bilgileri, üyeleri ve kullanıcıları ile yaptığı
                        sözleşmeler ile belirlenen amaçlar ve kapsam dışında üçüncü
                        kişilere açıklamayacaktır.
                    </p>
                    <br />
                    <p className="text-black">
                        Kombine Transferi, gerektiğinde kullanıcıların IP adreslerini tespit
                        etmekte ve bunu kullanmaktadır. IP adresleri, kullanıcıları genel
                        bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak
                        amacıyla da kullanılabilir.
                    </p>
                    <br />
                    <p className="text-black">
                        Üyelik veya Site üzerindeki çeşitli formların doldurulması ve
                        oylamaların yapılması suretiyle kullanıcıların kendileriyle ilgili
                        bir takım kişisel bilgileri (ad-soyadı, telefon, adres veya e-mail
                        adresleri gibi) Kombine Transferi’a vermeleri gerekmektedir. Kombine Transferi
                        tarafından talep edilen bilgiler ve/veya kullanıcı tarafından
                        sağlanan bilgiler ve/veya Site üzerinden yapılan işlemlerle ilgili
                        bilgiler; Kombine Transferi ve işbirliği içinde olduğu kişiler
                        tarafından, Kombine Transferi’nin üyeleri ve kullanıcıları ile yaptığı
                        sözleşmeler ile belirlenen amaçlar ve kapsam dışında da, üyelerin
                        ve kullanıcının kimliği ifşa edilmeden çeşitli istatistiksel
                        değerlendirmeler, veritabanı oluşturma ve pazar araştırmalarında
                        kullanılabilir.
                    </p>
                    <br />
                    <p className="text-black">
                        Kombine Transferi, Site dahilinde başka sitelere link verebilir.
                        Kombine Transferi, link vasıtasıyla erişilen sitelerin gizlilik
                        uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk
                        taşımamaktadır.
                    </p>
                    <br />
                    <p className="text-black">
                        Kombine Transferi, işbu gizlilik politikasında aksi belirtilmedikçe
                        kişisel ilgilerden herhangi birini Kombine Transferi’nin işbirliği içinde
                        olmadığı şirketlere ve üçüncü kişilere açıklamayacaktır. Aşağıda
                        belirtilen sınırlı durumlarda Kombine Transferi, işbu “Gizlilik
                        Politikası” hükümleri dışında kullanıcılara ait bilgileri üçüncü
                        kişilere açıklayabilir. Bu durumlar sınırlı sayıda olmak üzere;
                    </p>
                    <br />
                    <p className="text-black">
                        Yetkili hukuki otoriteler tarafından çıkarılan ve yürürlükte olan
                        Kanun, Kanun Hükmünde Kararname, Yönetmelik v.b. gibi hukuk
                        kurallarının getirdiği zorunluluklara uyulmasının; Kombine Transferi’nin
                        üyeleri ve kullanıcıları ile yaptığı sözleşmelerin gereklerinin
                        yerine getirilmesi ve bunların uygulamaya konulmasının; Yetkili
                        idari ve adli otorite tarafından usulüne göre yürütülen bir
                        araştırma veya soruşturmanın yürütümü amacıyla kullanıcılarla
                        ilgili bilgi talep edilmesinin ve kullanıcıların hakları veya
                        güvenliklerini korumak için bilgi verilmesinin gerekli olduğu
                        hallerdir.
                    </p>
                    <br />
                    <p className="text-black">
                        Kombine Transferi, kişisel bilgileri kesinlikle özel ve gizli tutmayı;
                        bunu bir sır saklama yükümlülüğü olarak addetmeyi ve gizliliğin
                        sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi
                        bir kısmının kamu alanına girmesini veya yetkisiz kullanımını veya
                        üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri
                        almayı ve gerekli özeni göstermeyi taahhüt etmektedir.
                        Kombine Transferi’ın gerekli bilgi güvenliği önlemlerini almasına karşın,
                        Site’ye ve sisteme yapılan saldırılar sonucunda gizli bilgilerin
                        zarar görmesi veya üçüncü kişilerin eline geçmesi durumunda,
                        Kombine Transferi’nin herhangi bir sorumluluğu olmayacaktır.
                    </p>
                    <br />
                    <p className="text-black">
                        Kombine Transferi, kullanıcılara ve kullanıcıların Site’yi kullanımına
                        dair bilgileri, teknik bir iletişim dosyasını (Cookie) kullanarak
                        elde edebilir. Bahsi geçen teknik iletişim dosyaları, ana bellekte
                        saklanmak üzere, bir web sitesinin, kullanıcının tarayıcısına
                        (browser) gönderdiği küçük metin dosyalarıdır. Teknik iletişim
                        dosyası, bir web sitesi hakkında durum ve tercihleri saklayarak,
                        internetin kullanımını kolaylaştırır. Teknik iletişim dosyası, bir
                        web sitesini kaç kişinin kullandığı; bir kişinin web sitesini
                        hangi amaçla, kaç kez ziyaret ettiği ve sitede ne kadar kaldığı
                        hakkında istatistiksel bilgileri elde etmek amacıyla
                        kullanılabilir. Ayrıca, kullanıcılar için özel olarak tasarlanmış
                        kullanıcı sayfalarından, dinamik olarak izinli pazarlama mesajı ve
                        içerik üretilmesine yardımcı olur. Teknik iletişim dosyası, ana
                        bellekten veya kullanıcıların e-mail adresinden veri veya başkaca
                        herhangi bir kişisel bilgi almak için oluşturulmamıştır.
                        Tarayıcıların pek çoğu, başta teknik iletişim dosyasını kabul eder
                        biçimde tasarlanmıştır; ancak kullanıcılar, dilerlerse teknik
                        iletişim dosyasının gelmemesi veya teknik iletişim dosyası
                        gönderildiğinde ikaz verilmesini sağlayacak biçimde, tarayıcı
                        ayarlarını değiştirebilirler.
                    </p>
                    <br />
                    <p className="text-black">
                        Kullanıcılar, kayıtlı verilerinin silinmesi için
                        team@kombinetransferi.com adresinden bizimle iletişime geçebilirler.
                    </p>
                    <br />
                    <p className="text-black">
                        Kombine Transferi, işbu Gizlilik Politikası hükümlerini, dilediği zaman
                        Site’de yayınlamak suretiyle, tek taraflı olarak değiştirebilir.
                        Kombine Transferi’nun değişiklik yaptığı Gizlilik Politikası hükümleri,
                        Site’de yayınlandığı tarih İtibariyle yürürlülüğe girer.
                    </p>
                    <br />
                    <p className="text-black">
                        Her türlü sorunuz için; info@kombinetransferi.com adresinden bizimle
                        iletişime geçebilirsiniz.
                    </p>
                    <br />
                </section >
            </main>
        );
    }
}