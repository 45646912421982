/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, createRef } from "react";
import logo from './assets/logo-black.png';
import logoRed from './assets/logo.png';
import apple from './assets/apple.svg';
import google from './assets/google.svg';
import instagram_kare from './assets/instagram_kare.png';
import homeMockup from './assets/home-mockup.png';
import listingDetailMockup from './assets/listing-detail-mockup.png';
import publicProfileMockup from './assets/public-profile-mockup.png';
import img_filtrele from './assets/filtrele.png';
import img_profil from './assets/profil.png';
import img_iletisim from './assets/iletisim.png';

import { BadgeDollarSign, ShieldCheck, ThumbsUp } from "lucide-react";

export default class Landing extends Component {
    constructor(props) {
        super(props);
        this.headline = createRef();
        this.howItWorks = createRef();
        this.firstFeature = createRef();
        this.secondFeature = createRef();
        this.thirdFeature = createRef();
        this.faq = createRef();
        this.footer = createRef();
        this.state = {
            isScrolled: false,
            faq: [
                {
                    question: 'Kombinemi Nasıl Transfer Ederim? (Passolig)',
                    answer: '&#10004; Kombinenizi Passolig Kartı olan başka bir kişiye aktarabilirsiniz. <br><br>Kombinenizi transfer etmek için:<br><strong>1-</strong> www.passo.com.tr" ye veya Passo Mobil Uygulaması’na"Üye girişi" yapın.<br><br><strong>2-</strong> Profilinizi görüntüleyin ve "Kombinelerim" sekmesine tıklayın.<br><br><strong>3-</strong> "Kombine Transferi" sekmesine tıklayın.<br><br><strong>4-</strong> Kombinenizi ve transfer etmek istediğiniz maçı seçin.<br><br><strong>5-</strong> &nbsp;Transfer edilecek kişinin TC Kimlik numarasını "TC Kimlik No" kutucuğuna yazın ve "Tanımla" butonunu tıklayın.<br><br><strong>6-</strong> "Bilet Transferi" başlığı altında transfer edilen kişinin TC Kimlik numarasını görüyorsanız ilgili maça ait hakkınız o kişiye transfer edilmiş demektir.<br>',
                    expanded: false
                },
                {
                    question: 'Maç Biletimi Nasıl Transfer Ederim? (Passolig)',
                    answer: '&#10004; Satın aldığınız maç biletinizi Passolig Kartı olan başka bir kişiye aktarabilirsiniz.<br><br>Biletinizi transfer etmek için:<br><strong>1-</strong> www.passo.com.tr" ye veya Passo Mobil Uygulaması’na "Üye girişi" yapın.<br><br><strong>2-</strong> Profilinizi görüntüleyin ve "Biletlerim" sekmesini açın.- Transfer etmek istediğiniz maçın karşısındaki "TC Kimlik No" kutucuğuna maç biletinizi transfer edeceğiniz kişinin TC Kimlik numarasını "yazın ve "Tanımla" butonuna basın.<br><br><strong>3-</strong> "Bilet Transferi" başlığı altında transfer edilen kişinin TC Kimlik numarasını görüyorsanız bilet o kişiye transfer edilmiş demektir.<br><br><strong>4-</strong> Transfer işleminin başarılı olması için transfer edilecek kişinin geçerli bir Passolig Kartının olması, aynı ya da izin verilen kart türüne sahip olması ve transferi yapılacak maçın biletine sahip olmaması gerekmektedir.<br>',
                    expanded: false
                },
                {
                    question: 'Maça Nasıl Giriş Yapacağım ? (Passolig)',
                    answer: '&#10004; Passo Mobil ile "Maça gir" özelliğini kullanabilirsiniz;<br><strong><br></strong>Passo Mobil Uygulaması’nda bulunan “Maça Gir” butonuna tıklayıp, cep telefonunuzun teknik özelliğine göre otomatik olarak ekrana çıkan QR kodu veya NFC ekranını turnikede okutarak tüm stadyumlarda maça girebilirsiniz.<br>',
                    expanded: false
                },
                {
                    question: 'Blok/Sıra/Koltuk Bilet bilgilerine nasıl ulaşabilirim? (Passolig)',
                    answer: '&#10004; <a href="https://www.passo.com.tr/tr?utm_source=passolig&amp;utm_medium=faq&amp;utm_campaign=buytickets" target="_blank">passo.com.tr</a>" den ve Passo Mobil uygulamasından "Üye Girişi" yaparak "Hesabım -> Biletlerim -> Futbol Biletlerim" sekmesinden görüntüleyebilirsiniz.<br>',
                    expanded: false
                },
                {
                    question: 'Kombine Transferi Uygulaması Nedir? Nasıl Kullanabilirim?',
                    answer: '&#10004; Kombine Transferi uygulaması <b>Google Play Store</b> ve <b>Apple Store</b> dan indirilebilen <b>ÜCRETSİZ</b> bir mobil uygulamadır. Cep telefonunuza yükleyerek kullanmaya başlayabilirsiniz',
                    expanded: false
                },
                {
                    question: 'Kombine Transferi Uygulaması Ne İşe Yarar?',
                    answer: '&#10004; Kombine Transferi mobil uygulaması, maç bileti bulmak ve satmak için alıcı ve satıcıyı buluşturan bir platformdur. Kombine Transferi uygulaması ile kombine sahipleri gidemedikleri maçın biletini <b>ÜCRETSİZ</b> ilana koyabilir. Bilet bulmak isteyenler ise yine <b>ÜCRETSİZ</b> olarak bu ilanları görüntüleyebilir ve satıcıyla iletişime geçebilir.',
                    expanded: false
                },
                {
                    question: 'Giriş Sayfası (Kombine Transferi Mobil Uygulaması)',
                    answer: '&#10004; Giriş sayfasından, isterseniz mevcut google ve apple hesaplarınızı kullanarak , isterseniz de hızlı bir şekilde yeni kullanıcı oluşturarak giriş yapabilirsiniz.<br><br>',
                    expanded: false
                },
                {
                    question: 'Bilet Bul Sekmesi (Kombine Transferi Mobil Uygulaması)',
                    answer: '&#10004; Bilet Bul sekmesinden, tüm müsabakalara ait ilanları görüntüleyebilir ve yukarıdaki butonlar aracılığı ile filtreleyebilirsiniz. İlan kutularının görünümleri seçilen dopinge göre farklılık gösterebilir.<br>',
                    expanded: false
                },
                {
                    question: 'İlan Ver Sekmesi (Kombine Transferi Mobil Uygulaması)',
                    answer: '&#10004; İlan Ver sekmesinden, elinizde bulunan kombine/ler için ilan oluşturabilirsiniz. (Sadece Premium üyeler aynı anda 1^ den fazla ilan oluşturabilir.) İsterseniz detay bilgilerini tamamladıktan sonra ilanınız için doping satın alabilirsiniz. 3 farklı doping tipi bulunmaktadır. “Kalın Yazı”, “Renkli Çerçeve” ve “Acil İkonu” .',
                    expanded: false
                },
                {
                    question: 'İlan Detay Sayfası(Kombine Transferi Mobil Uygulaması)',
                    answer: '&#10004; İstediğiniz ilana tıklayarak maça ve bilete ait detay bilgilere ulaşabilirsiniz.<br>',
                    expanded: false
                },
                {
                    question: 'Mesajlar Sekmesi (Kombine Transferi Mobil Uygulaması)',
                    answer: '&#10004; Mesajlar sekmesinden, istediğiniz kullanıcılar ile uygulama içerisinden ücretsiz ve anlık olarak iletişime geçebilirsiniz.',
                    expanded: false
                },
                {
                    question: 'Profilim Sekmesi (Kombine Transferi Mobil Uygulaması)',
                    answer: '&#10004; Profilim sekmesinden, hesabınız, ilanlarınız, favori ilanlarınız, favori kullanıcılarınız, kombine bilgileriniz yönetebilir, uygulama ile alakalı ayarları yapabilirsiniz.',
                    expanded: false
                },
                {
                    question: 'İlanlarım (Kombine Transferi / Profil Sekmesi)',
                    answer: '&#10004; Bu sayfada; yeni ilan verme, ilanı düzenleme, yayından kaldırma ve doping satın alma işlevlerini gerçekleştirebilirsiniz.<br>',
                    expanded: false
                },
                {
                    question: 'Favori İlanlar (Kombine Transferi / Profil Sekmesi)',
                    answer: '&#10004; Bu sayfada; favorilere eklediğiniz ilanlarınızı görüntüleyebilirsiniz (*Premium Üyelik).<br>',
                    expanded: false
                },
                {
                    question: 'Favori Kullanıcılar (Kombine Transferi / Profil Sekmesi)',
                    answer: '&#10004; Bu sayfada; favorilere eklediğiniz kullanıcıları görüntüleyebilirsiniz (*Premium Üyelik).<br>',
                    expanded: false
                },
                {
                    question: 'Kombinem (Kombine Transferi / Profil Sekmesi)',
                    answer: '&#10004; Bu sayfada; elinizde bulunan kombine biletlerini kaydedebilir ve ilan verirken otomatik olarak kullanabilirsiniz.<br>',
                    expanded: false
                },
                {
                    question: 'Telefon Numaramı Gizle (Kombine Transferi / Profil Sekmesi)',
                    answer: '&#10004; Telefon numaranızı gizlemek istediğinizde bu özelliği aktif hale getirebilirsiniz. (*Premium Üyelik)<br>',
                    expanded: false
                },
                {
                    question: 'Adımı Soyadımı Gizle (Kombine Transferi / Profil Sekmesi)',
                    answer: '&#10004; Ad-Soyadınızı gizlemek istediğinizde bu özelliği aktif hale getirebilirsiniz. (*Premium Üyelik)',
                    expanded: false
                },
                {
                    question: 'Premium Üyelik Avantajları Nelerdir?',
                    answer: '&#10004; Aynı anda sınırsız sayıda ilan ekleyebilirsiniz<br><br>&#10004; Sadece güvenli kullanıcıların ilanlarını görmek için ilanları filtreleyebilirsiniz.<br><br>&#10004; İlan sahibine tek tuşla whatsapp uygulaması üzerinden ulaşabilirsiniz.<br><br>&#10004; Favori kullanıcılarınızı listenize ekleyebilirsiniz.<br><br>&#10004; Favori ilanlarınızı listenize ekleyebilirsiniz.<br><br>&#10004; Telefon numaranızı diğer kullanıcılardan gizleyebilirsiniz.<br><br>&#10004; Adınızı ve Soyadınızı diğer kullanıcılardan gizleyebilirsiniz.<br><br>&#10004; Premium Destek Ekibimizden anında destek alabilirsiniz.<br><br>',
                    expanded: false
                },
                {
                    question: 'Premium Üyelik Ödemeleri (Kombine Transferi Mobil Uygulaması)',
                    answer: '&#10004; Üyelik ücreti seçilen süre için toplu olarak tahsil edilir, iptal edilmediği sürece süre bitiminde otomatik olarak tekrar uzar ve belirtilen tutar tekrar tahsil edilir.<br>',
                    expanded: false
                },
                {
                    question: 'Doping Ödemeleri (Kombine Transferi Mobil Uygulaması)',
                    answer: '&#10004; Doping ücreti tek seferlik tahsil edilir, tekrarlı değildir. İStenilen ilan için Dopinglerin kullanımı için süre sınırı yoktur.<br>',
                    expanded: false
                },
            ],
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('fade-in');
                        entry.target.classList.remove('fade-in-start');
                    } else {
                        entry.target.classList.remove('fade-in');
                        entry.target.classList.add('fade-in-start');
                    }
                });
            },
            { threshold: 0.1 }
        );

        observer.observe(this.headline.current);
        observer.observe(this.howItWorks.current);
        observer.observe(this.firstFeature.current);
        observer.observe(this.secondFeature.current);
        observer.observe(this.thirdFeature.current);
        observer.observe(this.faq.current);
        observer.observe(this.footer.current);

        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        const scrollTop = window.scrollY;
        if (scrollTop > 50) {
            this.setState({ isScrolled: true });
        } else {
            this.setState({ isScrolled: false });
        }
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }

        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isIOS = /iphone|ipad|ipod/.test(userAgent);

        return (
            <main className='mx-4 lg:mx-24 my-8 flex flex-col'>
                
                {/*
                <header className='flex lg:flex-row z-50 gap-y-4 items-center justify-between top-0 sticky glass-effect px-4 py-2'>
                    <a href="#top" >
                        <img src={logo} alt='logo' className='w-16 h-16 object-contain' />
                    </a>

                    <div className='flex flex-row lg:gap-2'>
                        <a href='https://www.instagram.com/kombinetransferi/'>
                                <img src={instagram_kare} alt='instagram' className='h-12 object-contain' />
                        </a>

                        <a href='https://apps.apple.com/tr/app/kombine-transferi/id6478542888'>
                                <img src={apple_kare} alt='apple' className='h-12 object-contain' />
                        </a>
                        
                        <a href='https://play.google.com/store/apps/details?id=com.kombinetransferi.kt_mobile'>
                                <img src={google_kare} alt='google' className='h-12 object-contain' />
                        </a>

                    </div>
                </header>
                */}

                {/* HEADLINE */}
                <section
                    ref={this.headline}
                    className="p-8 lg:p-4 fade-in-start">
                    <div className="flex flex-col items-center gap-y-4">
                        <img src={logoRed} alt='logo' className='w-24 h-24 object-contain' />

                        <h1 className="text-3xl lg:text-6xl text-center max-w-3xl mt-4">
                            kombine   transferi
                        </h1>

                        <p className="text-center w-full text-2xl text-red-500 font-medium">
                            <i>
                                Maç Bileti Bulma ve Satmanın <b>ÜCRETSİZ YENİ</b> Yolu
                            </i>
                        </p>

                        <div className='flex flex-row lg:gap-2'>
                            <a href='https://www.instagram.com/kombinetransferi/'>
                                <img src={instagram_kare} alt='instagram' className='h-12 object-contain marginRight5' />
                            </a>
                            <a href='https://apps.apple.com/tr/app/kombine-transferi/id6478542888'>
                                <img src={apple} alt='apple' className='h-12 object-contain marginRight5' />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.kombinetransferi.kt_mobile'>
                                <img src={google} alt='google' className='h-12 object-contain marginRight5' />
                            </a>
                        </div>

                        <div className="hidden md:flex flex-row items-center justify-evenly w-full mt-8">
                            <img
                                src={listingDetailMockup}
                                alt="logo"
                                className="h-[30vh] md:h-[50vh] lg:h-[70vh] object-contain rounded-3xl" />
                            <img
                                src={homeMockup}
                                alt="logo"
                                className="h-[40vh] md:h-[50vh] lg:h-[90vh] object-contain rounded-3xl" />
                            <img
                                src={publicProfileMockup}
                                alt="logo"
                                className="h-[30vh] md:h-[50vh] lg:h-[70vh] object-contain rounded-3xl" />
                        </div>

                        <div className="md:hidden flex flex-col items-center justify-evenly w-full mt-8 gap-y-8">
                            <img
                                src={listingDetailMockup}
                                alt="logo"
                                className="object-contain rounded-3xl" />
                            <img
                                src={homeMockup}
                                alt="logo"
                                className="object-contain rounded-3xl" />
                            <img
                                src={publicProfileMockup}
                                alt="logo"
                                className="object-contain rounded-3xl" />
                        </div>

                    </div>
                </section>

                {/* HOW IT WORKS */}
                <section
                    ref={this.howItWorks}
                    className='lg:my-8 lg:-mx-24 fade-in-start flex flex-col items-center bg-gray-50 lg:p-16'>
                    <span className="text-5xl">neden biz ?</span>
                    <div className='flex flex-col lg:flex-row gap-y-4 lg:gap-x-4 mt-10 lg:max-w-4xl'>
                        <div className='flex flex-col items-start bg-white shadow-sm rounded-3xl border border-red-500 p-8'>
                            <BadgeDollarSign size={40} />
                            <span className='text-2xl font-medium mt-8 text-red-500'>İlan Ücreti YOK !</span>
                            <span className="max-w-xs mt-3">
                                Bilet sahipleri maçın biletini <b>ÜCRETSİZ</b> ilana koyabilir.
                                <br></br>
                                <br></br>
                                Bilet almak isteyenler ise yine <b>ÜCRETSİZ</b> olarak bu ilanları görüntüleyebilir.
                                <br></br>
                                <br></br>
                                Üstelik <strong>REKLAMSIZ.</strong>
                            </span>
                        </div>

                        <div className='flex flex-col items-start bg-white shadow-sm rounded-3xl border border-red-500 p-8'>
                            <ThumbsUp size={40} />
                            <span className='text-2xl font-medium mt-8 text-red-500'>Yeni ve Kolay !</span>
                            <span className="max-w-xs mt-3">
                                Kullanıcı dostu arayüzler,
                                <br></br>
                                Kolay kullanım ve
                                <br></br>
                                Çağdaş tasarımı ile tam da ihtiyacınız olan şey.
                                <br></br>
                            </span>
                        </div>

                        <div className='flex flex-col items-start bg-white shadow-sm rounded-3xl border border-red-500 p-8'>
                            <ShieldCheck size={40} />
                            <span className='text-2xl font-medium mt-8 text-red-500'>Güvenli Satıcılar</span>
                            <span className="max-w-xs mt-3">
                                İç İşleri Bakanlığı - Nüfus ve Vatandaşlık İşleri G.M. tarafından doğrulanmış <strong>GERÇEK kişiler ve GÜVENLİ kullanıcılar</strong>.
                            </span>
                        </div>

                    </div>
                </section>

                {/* FEATURES */}
                <section
                    ref={this.firstFeature}
                    className='my-8 fade-in-start w-full items-center'>
                    <div className='flex flex-col lg:flex lg:flex-row items-center justify-center gap-x-40'>

                        {/*<img src={listingDetailMockup}*/}

                        <img src={img_filtrele}
                            alt='mockup'
                            className='lg:h-[76vh] object-contain' />

                        <div className='flex flex-col gap-y-4 lg:max-w-lg mt-8 lg:mt-0'>
                            <span className='text-lg text-gray-500'>kombine transferi</span>
                            <span className='text-4xl font-bold'>ilanları kolayca filtreleyin ve sıralayın</span>
                            <span className='text-gray-500'>İstediğiniz takım, fiyat, blok adına göre filtreleyebilir, veya sadece güvenli kullanıcıların ilanlarını görmek isteyebilirsiniz.</span>
                        </div>
                    </div>
                </section>

                <section
                    ref={this.secondFeature}
                    className='my-8 fade-in-start w-full items-center'>
                    <div className="flex flex-col-reverse lg:flex-row items-center justify-center gap-x-40">
                        <div className="flex flex-col gap-y-4 lg:max-w-lg mt-8 lg:mt-0">
                            <span className="text-lg text-gray-500">kombine transferi</span>
                            <span className="text-4xl font-bold">ilan ve kullanıcıları favorilerinize ekleyin</span>
                            <span className="text-gray-500">İstediğiniz ilanları ve kullanıcıları favorilerinize ekleyip daha sonra kolayca ulaşabilirsiniz.</span>
                        </div>

                        <img
                            src={img_profil}
                            alt="mockup"
                            className="lg:h-[76vh] object-contain"
                        />
                    </div>
                </section>

                <section
                    ref={this.thirdFeature}
                    className='my-8 fade-in-start w-full items-center'>
                    <div className='flex flex-col lg:flex lg:flex-row items-center justify-center gap-x-40'>
                        <img src={img_iletisim}
                            alt='mockup'
                            className='lg:h-[76vh] object-contain' />

                        <div className='flex flex-col gap-y-4 lg:max-w-lg mt-8 lg:mt-0'>
                            <span className='text-lg text-gray-500'>kombine transferi</span>
                            <span className='text-4xl font-bold'>çağdaş, kolay ve hızlı</span>
                            <span className='text-gray-500'>Kullanıcılarla uygulama içinden mesajlaşabileceğiniz gibi hızlı bir şekilde telefon araması yapabilir veya whatsapp mesajı atabilirsiniz.</span>
                        </div>
                    </div>
                </section>

                {/* FAQ */}
                <section
                    ref={this.faq}
                    className='lg:my-8 lg:-mx-24 fade-in-start flex flex-col items-center bg-gray-50 lg:p-16'>
                    <img src={logoRed} alt='logo' className='w-24 h-24 object-contain' />
                    <span className="text-4xl font-bold mt-8">Merak Ettiğiniz Sorular</span>
                    <div className='mt-5 flex flex-row lg:gap-2'>
                            <a href='https://www.instagram.com/kombinetransferi/'>
                                <img src={instagram_kare} alt='instagram' className='h-12 object-contain marginRight5' />
                            </a>
                            <a href='https://apps.apple.com/tr/app/kombine-transferi/id6478542888'>
                                <img src={apple} alt='apple' className='h-12 object-contain marginRight5' />
                            </a>
                            <a href='https://play.google.com/store/apps/details?id=com.kombinetransferi.kt_mobile'>
                                <img src={google} alt='google' className='h-12 object-contain marginRight5' />
                            </a>
                    </div>
                    <span className="px-4 lg:px-0 lg:max-w-4xl text-center mt-4 text-red-500">Passolig altyapısı ve Kombine Transferi Uygulaması ile ilgili merak ettiğiniz soruların cevapları burada</span>

                    <div className='mt-10 w-full max-w-3xl'>
                        {
                            this.state.faq.map((item, index) => (
                                <div key={index} className='flex flex-col gap-y-2 mb-4 bg-white border border-red-500 rounded-lg'>
                                    <button
                                        onClick={() => {
                                            const faq = [...this.state.faq];
                                            faq[index].expanded = !faq[index].expanded;
                                            this.setState({ faq });
                                        }}
                                        className='flex flex-row items-center justify-between py-4 px-8'>
                                        <span className='text-lg font-medium'>{item.question}</span>
                                        <span className='text-lg font-medium'>
                                            {item.expanded ? 'x' : '+'}
                                        </span>

                                    </button>
                                    {
                                        item.expanded &&
                                        <span className='text-lg px-8 pb-4 text-gray-700'>
                                            <span dangerouslySetInnerHTML={{ __html: item.answer }}></span>
                                        </span>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </section>

                {/* FOOTER */}
                <section
                    ref={this.footer}
                    className='my-8 fade-in-start'>
                    <div className='flex flex-col lg:flex-row items-center justify-between gap-8'>
                        <img src={logo} alt='logo' className='w-16 h-16 object-contain' />

                        <nav className='flex flex-wrap items-center justify-center gap-2'>
                            <a href='/gizlilik-sozlesmesi'
                                className='text-base font-medium mx-4'>Gizlilik Sözleşmesi</a>
                            <a href='/kullanim-sartlari'
                                className='text-base font-medium mx-4'>Kullanım Şartları</a>
                            <a href='/hakkimizda'
                                className='text-base font-medium mx-4'>Hakkımızda</a>
                        </nav>

                        <div className='flex flex-row lg:gap-2'>
                            <a href='https://www.instagram.com/kombinetransferi/'>
                                <img src={instagram_kare} alt='instagram' className='h-12 object-contain marginRight5' />
                            </a>
                            
                            <a href='https://apps.apple.com/tr/app/kombine-transferi/id6478542888'>
                                <img src={apple} alt='apple' className='h-12 object-contain marginRight5' />
                            </a>
                            
                            <a href='https://play.google.com/store/apps/details?id=com.kombinetransferi.kt_mobile'>
                                <img src={google} alt='google' className='h-12 object-contain marginRight5' />
                            </a>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}